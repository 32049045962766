<template>
  <b-modal id="SABModal" size="lg" :hide-footer="true" :hide-header="true" modal-class="widget-modal">
    <div class="text-center"><h5>Create Widget</h5></div>
    <div class="row">
      <div class="col pb-5">
        <h3 class="h3 text-center mb-4">Add a Service Area Business(SAB) with CID</h3>
        <div class="row">
          <div class="col-8">
            <input v-model="cid" placeholder="Enter CID" type="text" class="form-control" :class="{ 'is-invalid': failed }">
            <ul v-if="errors.cid.length" class="text-danger mt-2">
              <li v-for="error in errors.cid" :key="error">{{error}}</li>
            </ul>
          </div>
          <div class="col-4">
            <button :disabled="createIsBusy" class="form-control btn btn-success" type="button" @click="generateSABWidget">
              <b-spinner v-if="createIsBusy" small class="m-auto"></b-spinner>
              Generate Widget
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      createIsBusy:false,
      cid:null,
      errors: {
        cid:[]
      },
      failed:false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },
  methods:{
    generateSABWidget(){
      // Create Widget
      this.createIsBusy = true;
      this.errors = {
        cid:[]
      };
      this.failed = false;
      this.$store
          .dispatch('widgets/createSab', { agency_id: this.user.agency.id, cid:this.cid })
          .then((response) => {
            this.createIsBusy = false;
            if(response.success) {
              this.$emit('widgetCreated');
              this.cid = '';
            }else{
              this.failed = true;
              this.errors = {
                cid:response.message
              };
            }
          })
          .catch((err) => {
            if(err.response.data.errors.hasOwnProperty('cid')){
              this.failed = true;
            }
            this.errors = err.response.data.errors;
            this.createIsBusy = false;
          })
    }
  }
}
</script>
<style scoped>
  .btn-success:disabled{
    background: #00b050;
  }
</style>
